:root {
  /* Layout */

  /* Colors */
  --primary-color: #1a1a1a;
  --yellow: #ffd800;
  --orange: #f6670b;
  --red: #bd1c2b;
  --green: #009933;
  --purple: #3f51b5;
}

* {
  box-sizing: border-box;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

body {
  background: #fff;
  color: #1a1a1a;
  color: var(--primary-color);
  margin: 0px;
  padding: 0px;
}

a:focus,
button:focus,
div:focus,
select:focus,
input:focus {
  outline: none !important;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans TC", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

p {
  line-height: 1.8;
  font-size: 18px;
}

h1 {
  font-size: 46px;
}

h2 {
  font-size: 20px;
}

.container {
  padding: 0px 35px 0px 35px;
  margin: 0 auto;
}

.container-min {
  padding: 0px 35px 0px 35px;
  margin: 0 auto;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  #header-contact {
    display: none;
  }

  #banner-wrapper {
    margin-top: 65px;
  }

  #header {
    height: 65px;
  }

  #topnav {
    display: none;
  }
}

@media (max-width: 992px) {
  #contact-box {
    display: none;
  }

  #banner-text-intro {
    margin-right: 0px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 992px;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  #footer-item-1 {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 1920px) {
  .container {
    width: 1300px;
  }
}

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.91e3cd3f.eot);
  src: url(/static/media/fontello.91e3cd3f.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.e671a230.woff2) format('woff2'),
       url(/static/media/fontello.d8c441f2.woff) format('woff'),
       url(/static/media/fontello.d991b78b.ttf) format('truetype'),
       url(/static/media/fontello.4af357ba.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?72127396#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-phone:before { content: '\E800'; } /* '' */
.icon-mail:before { content: '\E801'; } /* '' */
.icon-emo-wink:before { content: '\E802'; } /* '' */
.icon-emo-happy:before { content: '\E803'; } /* '' */
.icon-check:before { content: '\E804'; } /* '' */
.icon-left-open:before { content: '\E805'; } /* '' */
.icon-right-open:before { content: '\E806'; } /* '' */
.icon-left-open-big:before { content: '\E807'; } /* '' */
.icon-right-open-big:before { content: '\E808'; } /* '' */
.icon-emo-grin:before { content: '\E80C'; } /* '' */
.icon-facebook:before { content: '\F09A'; } /* '' */
.icon-linkedin:before { content: '\F0E1'; } /* '' */
.icon-youtube:before { content: '\F167'; } /* '' */
.icon-whatsapp:before { content: '\F232'; } /* '' */
.icon-instagram:before { content: '\F31E'; } /* '' */
.icon-instagram-1:before { content: '\F32D'; } /* '' */
footer {
	color: #ccc !important;
  background-color: #0d0d0d;
  padding: 15px 0 60px 0;
  line-height: 1.8;
}

footer span, 
footer a {
  font-size: 16px;
}

footer img.footer-logo {
  margin: 45px 0px 25px 0px;
}

footer .footer-copyright {
  margin-top: 10px;
}

footer a {	
	color: #ccc;	
  text-decoration: none;
  transition: .3s;
}

footer a:hover {
  color: #fff;
  transition: .3s;
}

footer .footer-nav::after {
	content: '';
	display: block;
	width: 0;
	height: 1px;
	background: #ccc;
	transition: width .3s;	
	margin-top: -5px;
}

footer .footer-nav:hover::after {	
	width: 50px;
	transition: width .3s;
}

footer h1 {	
	font-size: 18px;
	font-weight: 500;	
	margin: 45px 0px 25px 0px;	
	color: #fff;	
}

footer ul {
  margin: 0;
	padding: 0;
  list-style-type: none;
}

footer li {
  line-height: 2.1;
}

footer i {
	margin-right: 7px;
}

.social-media {
  margin-top: 25px;
	width: 135px;	
}

.social-media img {
	opacity: .85;
	transition: all 0.3s ease;
}

.social-media img:hover {
	opacity: 1;
	transition: all 0.3s ease;
}


.tabelatiny1,
#secaoPrincipal table.tabelatiny1,
.mceContentBody table.tabelatiny1,
#conteudoImpressao .formLinha .organizado table.tabelatiny1 {
    background-color: #fff;
    margin: 12px 0 12px 0;
    width: 100%;
    border-bottom: solid 1px #bbb;
    border-right: solid 1px #bbb;
    border-collapse: collapse;
}
.tabelatiny1 td,
#secaoPrincipal table.tabelatiny1 td,
.mceContentBody table.tabelatiny1 td,
#conteudoImpressao .formLinha .organizado table.tabelatiny1 td {
    border-left: solid 1px #bbb;
    border-top: solid 1px #bbb;
    padding: 2px;
}
.tabelatiny1 th,
#secaoPrincipal table.tabelatiny1 th,
.mceContentBody table.tabelatiny1 th,
#conteudoImpressao .formLinha .organizado table.tabelatiny1 th {
    border-left: solid 1px #bbb;
    border-top: solid 1px #bbb;
    background: #d4e8f4;
    padding: 2px;
    color: #3b3b3b;
    font-weight:600;
}
