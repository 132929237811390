:root {
  /* Layout */

  /* Colors */
  --primary-color: #1a1a1a;
  --yellow: #ffd800;
  --orange: #f6670b;
  --red: #bd1c2b;
  --green: #009933;
  --purple: #3f51b5;
}

* {
  box-sizing: border-box;
  border-collapse: collapse;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

body {
  background: #fff;
  color: var(--primary-color);
  margin: 0px;
  padding: 0px;
}

a:focus,
button:focus,
div:focus,
select:focus,
input:focus {
  outline: none !important;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans TC", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

p {
  line-height: 1.8;
  font-size: 18px;
}

h1 {
  font-size: 46px;
}

h2 {
  font-size: 20px;
}

.container {
  padding: 0px 35px 0px 35px;
  margin: 0 auto;
}

.container-min {
  padding: 0px 35px 0px 35px;
  margin: 0 auto;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  #header-contact {
    display: none;
  }

  #banner-wrapper {
    margin-top: 65px;
  }

  #header {
    height: 65px;
  }

  #topnav {
    display: none;
  }
}

@media (max-width: 992px) {
  #contact-box {
    display: none;
  }

  #banner-text-intro {
    margin-right: 0px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 992px;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  #footer-item-1 {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 1920px) {
  .container {
    width: 1300px;
  }
}
