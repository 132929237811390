.tabelatiny1,
#secaoPrincipal table.tabelatiny1,
.mceContentBody table.tabelatiny1,
#conteudoImpressao .formLinha .organizado table.tabelatiny1 {
    background-color: #fff;
    margin: 12px 0 12px 0;
    width: 100%;
    border-bottom: solid 1px #bbb;
    border-right: solid 1px #bbb;
    border-collapse: collapse;
}
.tabelatiny1 td,
#secaoPrincipal table.tabelatiny1 td,
.mceContentBody table.tabelatiny1 td,
#conteudoImpressao .formLinha .organizado table.tabelatiny1 td {
    border-left: solid 1px #bbb;
    border-top: solid 1px #bbb;
    padding: 2px;
}
.tabelatiny1 th,
#secaoPrincipal table.tabelatiny1 th,
.mceContentBody table.tabelatiny1 th,
#conteudoImpressao .formLinha .organizado table.tabelatiny1 th {
    border-left: solid 1px #bbb;
    border-top: solid 1px #bbb;
    background: #d4e8f4;
    padding: 2px;
    color: #3b3b3b;
    font-weight:600;
}