footer {
	color: #ccc !important;
  background-color: #0d0d0d;
  padding: 15px 0 60px 0;
  line-height: 1.8;
}

footer span, 
footer a {
  font-size: 16px;
}

footer img.footer-logo {
  margin: 45px 0px 25px 0px;
}

footer .footer-copyright {
  margin-top: 10px;
}

footer a {	
	color: #ccc;	
  text-decoration: none;
  transition: .3s;
}

footer a:hover {
  color: #fff;
  transition: .3s;
}

footer .footer-nav::after {
	content: '';
	display: block;
	width: 0;
	height: 1px;
	background: #ccc;
	transition: width .3s;	
	margin-top: -5px;
}

footer .footer-nav:hover::after {	
	width: 50px;
	transition: width .3s;
}

footer h1 {	
	font-size: 18px;
	font-weight: 500;	
	margin: 45px 0px 25px 0px;	
	color: #fff;	
}

footer ul {
  margin: 0;
	padding: 0;
  list-style-type: none;
}

footer li {
  line-height: 2.1;
}

footer i {
	margin-right: 7px;
}

.social-media {
  margin-top: 25px;
	width: 135px;	
}

.social-media img {
	opacity: .85;
	transition: all 0.3s ease;
}

.social-media img:hover {
	opacity: 1;
	transition: all 0.3s ease;
}

